import { FC, useState } from 'react';

import { AssetBank, Upload } from '@assets/icons';
import { Button } from '@components/index';
import { ASSET_INFO } from '@pages/home-page/constants';
import { AssetType } from '@pages/home-page/enums';
import { DataItem } from '@pages/home-page/types';

import AddEditModalLayout from './AddEditModalLayout';
import AssetIdInput from '../AssetIdInput';
import ItemUploadModal from './ItemUploadModal';

interface ItemUploadWrapperModalProps {
  isOpen: boolean;
  isEdit?: boolean;
  assetType: AssetType;
  onCloseClick: () => void;
  defaultValues?: DataItem;
}

const ItemUploadWrapperModal: FC<ItemUploadWrapperModalProps> = ({
  isOpen,
  isEdit = false,
  assetType,
  onCloseClick,
  defaultValues,
}) => {
  const [isUpload, setIsUpload] = useState(assetType !== AssetType.RAW_FILE);

  const assetName = ASSET_INFO[assetType].name.toLowerCase();

  const renderSeparator = () => (
    <>
      <div className="flex-grow border-t border-gray-2"></div>
      <span className="mx-4 text-gray-500">or</span>
      <div className="flex-grow border-t border-gray-2"></div>
    </>
  );

  // To be changed after BE validation issue is fixed
  const renderAssetBankUpload = assetType === AssetType.RAW_FILE && (
    <>
      <div className="px-8 pb-4">
        <Button
          label="Upload from 'The Asset Bank'"
          IconLeftComponent={AssetBank}
          onClick={() => setIsUpload(false)}
          classOverride="border-gray-4 p-2 font-normal"
        />
      </div>
      <div className="flex items-center my-3 px-8">{renderSeparator()}</div>
    </>
  );

  return (
    <AddEditModalLayout
      isOpen={isOpen}
      header={`${isEdit ? 'Edit' : 'Add'} ${assetName}`}
      onClose={onCloseClick}
    >
      <div>
        {isEdit ? (
          <ItemUploadModal
            defaultValues={defaultValues}
            isEdit={isEdit}
            assetType={assetType}
            onCloseClick={onCloseClick}
          />
        ) : (
          <>
            {isUpload ? (
              <>
                {renderAssetBankUpload}
                <ItemUploadModal
                  defaultValues={defaultValues}
                  isEdit={isEdit}
                  assetType={assetType}
                  onCloseClick={onCloseClick}
                />
              </>
            ) : (
              <div className="px-8 pb-8">
                <AssetIdInput showImportButton={true} classOverride="w-full" />
                <div className="flex items-center my-3">
                  {renderSeparator()}
                </div>
                <div className="mt-4">
                  <Button
                    label="Upload File"
                    IconLeftComponent={Upload}
                    onClick={() => setIsUpload(true)}
                    classOverride="p-2 font-normal"
                  />
                </div>
                <div />
              </div>
            )}
          </>
        )}
      </div>
    </AddEditModalLayout>
  );
};
export default ItemUploadWrapperModal;

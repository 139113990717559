import { FC, MouseEventHandler } from 'react';

import { FolderEmpty } from '@assets/icons';
import { Checkbox } from '@components/index';

import { FilterData } from './AttributeFilter';

export interface FilterContainerProps {
  header: string;
  filterDatas: FilterData[];
  selectedData: FilterData[];
  onSelectOption: Function;
  onSelectAll: MouseEventHandler;
  onResetAll: MouseEventHandler;
}

const FilterContainer: FC<FilterContainerProps> = ({
  filterDatas,
  onSelectOption,
  selectedData,
  header,
  onSelectAll,
  onResetAll,
}) => {
  return (
    <div className="p-2">
      <div className="flex justify-between">
        <p className="text-black font-bold text-sm">{header}</p>
        <div>
          <span
            className="border-r-2 border-gray-6 pr-2 text-sm cursor-pointer"
            onClick={onSelectAll}
          >
            Select all
          </span>
          <span className="pl-2 text-sm cursor-pointer" onClick={onResetAll}>
            Reset all
          </span>
        </div>
      </div>
      <div className="!max-h-[180px] overflow-y-auto py-2">
        {filterDatas.length > 0 ? (
          filterDatas.map(filterData => {
            if (filterData.id === undefined) return null;
            const isChecked = selectedData.some(
              selected => selected.id === filterData.id,
            );

            return (
              <div key={filterData.id} className="w-full py-2 ">
                <Checkbox
                  label={filterData.label}
                  onChange={() => onSelectOption(filterData)}
                  checked={isChecked}
                />
              </div>
            );
          })
        ) : (
          <tr>
            <td colSpan={4} className="text-center py-8">
              <div className="flex flex-col items-center justify-center gap-1">
                <FolderEmpty className="h-8 w-8" />
                <p className="text-sm text-dark-3 font-semibold">
                  No results found
                </p>
                <p className="text-sm text-dark-3">
                  Your search did not match the existing attributes or documents
                  given. Please try again.
                </p>
              </div>
            </td>
          </tr>
        )}
      </div>
    </div>
  );
};

export default FilterContainer;

import { Dispatch, FC, SetStateAction } from 'react';

import { AssetType } from '@pages/home-page/enums';
import { DataItem, SelectedAsset } from '@pages/home-page/types';

import AddEditAttributeModal from './AddEditAttributeModal';
import AddEditColorAttribute from './AddEditColorAttribute';
import ItemUploadWrapperModal from './ItemUploadWrapperModal';

interface AddEditModalProps {
  selectedAsset: SelectedAsset | null;
  setSelectedAction: Dispatch<SetStateAction<SelectedAsset | null>>;
  isEdit: boolean;
}

const AddEditModals: FC<AddEditModalProps> = ({
  selectedAsset,
  setSelectedAction,
  isEdit,
}) => {
  if (!selectedAsset) return null;

  const AddModal = {
    [AssetType.KNOWLEDGE]: AddEditAttributeModal,
    [AssetType.COLORS]: AddEditColorAttribute,
  };

  const getAddModal = () => {
    const AddModalComponent =
      AddModal[selectedAsset.assetType as AssetType] || ItemUploadWrapperModal;

    return (
      <AddModalComponent
        isOpen={true}
        onCloseClick={() => setSelectedAction(null)}
        assetType={selectedAsset.assetType as AssetType}
        isEdit={isEdit}
        defaultValues={selectedAsset.asset as DataItem}
      />
    );
  };

  return getAddModal();
};

export default AddEditModals;

import { FC, useState } from 'react';

import { ASSET_INFO, ASSET_TABLE_HEADERS } from '@pages/home-page/constants';
import { AssetType } from '@pages/home-page/enums';
import {
  CollapsibleItemsState,
  DataItem,
  GroupedBrandData,
} from '@pages/home-page/types';
import { doesValueExist, filterResults } from '@pages/home-page/utils';

import CollapsibleAssetItem, { SavedFilter } from './CollapsibleAssetItem';

interface AssetTableProps {
  searchKeyword: string;
  collapsibleItemsState: CollapsibleItemsState;
  groupedBrandData: GroupedBrandData;
}

const AssetTable: FC<AssetTableProps> = ({
  searchKeyword,
  collapsibleItemsState,
  groupedBrandData,
}) => {
  const [savedFilter, setSavedFilters] = useState<SavedFilter>({
    category: [],
    document: [],
  });
  const { brandAssets, isLoadingBrandAssets } = groupedBrandData;

  const getDataItems = (id: AssetType) => {
    switch (id) {
      case AssetType.LOGO:
        return filterResults(searchKeyword, brandAssets.logoItems);
      case AssetType.FONT:
        return filterResults(searchKeyword, brandAssets.fontItems);
      case AssetType.COLORS:
        return filterResults(searchKeyword, brandAssets.colorItems);
      case AssetType.RAW_FILE:
        return searchKeyword
          ? brandAssets?.documentItems?.filter(
              (item: DataItem) =>
                doesValueExist(item.name || '', searchKeyword) ||
                doesValueExist(item.createdBy, searchKeyword) ||
                doesValueExist(item.createdAt, searchKeyword),
            )
          : brandAssets.documentItems;
      case AssetType.PRODUCT:
        return filterResults(searchKeyword, brandAssets.imageItems);
      case AssetType.VIDEO:
        return filterResults(searchKeyword, brandAssets.videoItems);
      case AssetType.KNOWLEDGE:
        return filterResults(searchKeyword, brandAssets.knowledgeItems);
      default:
        return [];
    }
  };

  const getAssetRow = (assetType: AssetType) => {
    return {
      id: assetType,
      header: ASSET_INFO[assetType].name,
      tooltipMessage: '',
      dataItems: getDataItems(assetType),
      imageClassName: `object-fill rounded ${
        assetType === AssetType.LOGO ? 'h-10 bg-gray-2 p-1.5' : 'h-[84px]'
      }`,
    };
  };

  return (
    <div className="border rounded-lg mt-1 overflow-y-auto h-[calc(100vh-345px)]">
      <table className="w-full border-collapse">
        <thead>
          <tr className="text-left text-dark-1">
            {ASSET_TABLE_HEADERS.map(
              (item: { name: string; classOverride: string }) => (
                <th
                  key={item.name}
                  className={`h-11 bg-gray-7 z-[100] text-sm text-dark-1 sticky top-0 font-semibold ${item.classOverride}`}
                >
                  {item.name}
                </th>
              ),
            )}
          </tr>
        </thead>
        <tbody className="w-full">
          {Object.keys(AssetType).map(assetType => {
            const assetItem = getAssetRow(assetType as AssetType);
            if (
              !searchKeyword ||
              (assetItem.dataItems && assetItem.dataItems.length > 0)
            )
              return (
                <CollapsibleAssetItem
                  savedFilter={savedFilter}
                  setSavedFilters={setSavedFilters}
                  key={assetType}
                  assetItem={assetItem}
                  collapsibleItemsState={collapsibleItemsState}
                  isLoading={isLoadingBrandAssets}
                  isFiltered={!!searchKeyword}
                />
              );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default AssetTable;

import { useRef, useState } from 'react';

import { isEmpty } from 'lodash';
import ReactTextareaAutosize from 'react-textarea-autosize';
import { useRecoilState } from 'recoil';

import { Send } from '@assets/icons';
import { LockedButton, PermissionAccess } from '@components/index';
import { UserPermissions } from '@enums/user-permissions';
import { LANGUAGE_OPTIONS } from '@pages/home-page/constants';
import { chatLanguage } from '@store/atoms/chat';

import { ChatLanguageSelect } from './ChatLanguageSelect';
import { useChatGeneration } from './hooks/use-chat-generation';

const ChatBox = () => {
  const { onGenerateChat } = useChatGeneration();

  const chatInputRef = useRef<HTMLTextAreaElement>(null);
  const [isEmptyChatInput, setIsEmptyChatInput] = useState(true);
  const [selectedLanguage, setSelectedLanguage] = useRecoilState(chatLanguage);

  const chatInput = chatInputRef.current;

  const onSendChatRequest = () => {
    if (chatInput && chatInput.value.trim()) {
      onGenerateChat(chatInput.value, selectedLanguage.id);
      chatInput.value = '';
      setIsEmptyChatInput(true);
    }
  };

  const onSubmitChat = () => {
    onSendChatRequest();
  };

  return (
    <div className="p-4 space-y-4 shadow-lg border-t">
      <div className="flex flex-col gap-3">
        <ChatLanguageSelect
          options={LANGUAGE_OPTIONS}
          selectedLanguage={selectedLanguage}
          onLanguageSelect={language => setSelectedLanguage(language)}
        />
        <PermissionAccess
          permission={UserPermissions.INTERACT_WITH_CHAT}
          AllowedContent={
            <div className="h-fit flex justify-between items-center space-x-2 p-2 border border-gray-4 rounded">
              <ReactTextareaAutosize
                maxRows={4}
                ref={chatInputRef}
                className="w-full text-sm focus:ring-transparent focus:outline-none text-wrap no-scrollbar resize-none placeholder-gray-5"
                placeholder="Chat with me"
                onKeyDown={e => {
                  if (e.key !== 'Enter' || e.shiftKey) return;

                  e.preventDefault();

                  if (!isEmptyChatInput) {
                    onSendChatRequest();
                  }
                }}
                onChange={e => {
                  if (isEmpty(e.currentTarget.value.trim())) {
                    return setIsEmptyChatInput(true);
                  }

                  setIsEmptyChatInput(false);
                }}
              />
              <Send
                className={`${!isEmptyChatInput ? 'stroke-gray-9 cursor-pointer' : ''}`}
                onClick={onSubmitChat}
              />
            </div>
          }
          DeniedContent={
            <div className="h-fit flex justify-between items-center space-x-2 p-2 border border-gray-4 rounded">
              <ReactTextareaAutosize
                disabled
                className="bg-white w-full text-sm focus:ring-transparent focus:outline-none text-wrap no-scrollbar resize-none placeholder-gray-5"
              />
              <LockedButton buttonClassname="w-fit h-8" />
            </div>
          }
        />
      </div>
    </div>
  );
};

export default ChatBox;

import { useSetRecoilState } from 'recoil';

import { selectedChatSuggestion } from '@store/atoms/chat';

const suggestions = [
  'Describe the tone of the brand in less than 1000 characters.',
  'List down keywords that our brand can not use for marketing. ',
  'What are the guidelines for the primary logo ?',
];

const SuggestionButton = ({
  text,
  onTextClick,
}: {
  text: string;
  onTextClick: (text: string) => void;
}) => {
  return (
    <button
      className="px-2 py-3 bg-gray-7 text-start rounded-lg text-sm"
      onClick={() => onTextClick(text)}
    >
      {text}
    </button>
  );
};

const ChatSuggestions = () => {
  const setSelectedChat = useSetRecoilState(selectedChatSuggestion);

  const onSuggestionClick = (text: string) => {
    setSelectedChat(text);
  };

  return (
    <div className="flex flex-col space-y-2 p-4">
      <div className="text-gray-3 text-sm">
        Not sure where to start? Choose any example below and see what we can
        do.
      </div>
      {suggestions.map((text: string) => (
        <SuggestionButton
          key={text}
          text={text}
          onTextClick={onSuggestionClick}
        />
      ))}
    </div>
  );
};

export default ChatSuggestions;

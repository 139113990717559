import { isEmpty } from 'lodash';
import { InfiniteData, useMutation, useQueryClient } from 'react-query';

import { HttpMethod } from '@api/enums';
import { createHttpClient } from '@api/http-client';
import { END_POINTS } from '@constants/api-endpoints';
import { QUERY_KEYS } from '@constants/query-keys';
import { AlertType } from '@enums/alert';
import { showAlert } from '@lib/alert/alert-manager';

import { AssetListResponse } from '../types';

export const useDeleteAsset = () => {
  const client = createHttpClient();
  const queryClient = useQueryClient();

  const mutation = useMutation(
    ({ assetId }: { assetId: number; isDocumentDelete?: boolean }) =>
      client({
        method: HttpMethod.DELETE,
        url: END_POINTS.BRAND_LIBRARY.DELETE_ASSET(assetId),
      }),
    {
      onSuccess: (_, variables) => {
        if (variables.isDocumentDelete) {
          queryClient.refetchQueries(
            QUERY_KEYS.BRAND_LIBRARY.BRAND_ASSETS_LIST,
          );
          queryClient.refetchQueries(QUERY_KEYS.HIERARCHY.ASSETS_HIERARCHY);

          showAlert({
            header: 'Documents and Attributes deleted',
            message:
              'The document and associated attributes has been successfully deleted',
            type: AlertType.SUCCESS,
          });
        } else {
          const cache = queryClient.getQueryData<
            InfiniteData<AssetListResponse>
          >(QUERY_KEYS.BRAND_LIBRARY.BRAND_ASSETS_LIST);

          if (isEmpty(cache?.pages)) {
            return;
          }
          const updatedPages = cache?.pages.map(page => ({
            ...page,
            items: page.items.filter(item => item.id !== variables.assetId),
          }));

          queryClient.setQueryData<InfiniteData<AssetListResponse>>(
            QUERY_KEYS.BRAND_LIBRARY.BRAND_ASSETS_LIST,
            {
              ...cache,
              pages: updatedPages,
            },
          );

          showAlert({
            message: 'Asset deleted successfully',
            type: AlertType.SUCCESS,
          });
        }
      },
      onError: () => {
        showAlert({
          type: AlertType.ERROR,
          message: 'Failed to delete asset',
        });
      },
    },
  );

  return mutation;
};

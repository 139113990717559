import { FC, useState } from 'react';

import { Cross, ZoomIn, ZoomOut } from '@assets/icons';
import { Modal } from '@components/index';
import {
  MAX_ZOOM_LEVEL,
  MIN_ZOOM_LEVEL,
  ZOOM_STEP,
} from '@pages/home-page/constants';

interface ImagePreviewModalProps {
  isOpen: boolean;
  onClose: () => void;
  fileName: string;
  imageUrl: string;
  externalAssetId?: string;
}

const ImagePreviewModal: FC<ImagePreviewModalProps> = ({
  isOpen,
  onClose,
  fileName,
  imageUrl,
  externalAssetId,
}) => {
  const [zoomLevel, setZoomLevel] = useState(1);

  const handleZoomIn = (e: React.PointerEvent<Element>) => {
    e.stopPropagation();
    setZoomLevel(prev => Math.min(prev + ZOOM_STEP, MAX_ZOOM_LEVEL));
  }; // Limit max zoom level

  const handleZoomOut = (e: React.PointerEvent<Element>) => {
    e.stopPropagation();
    setZoomLevel(prev => Math.max(prev - ZOOM_STEP, MIN_ZOOM_LEVEL));
  }; // Limit min zoom level

  return (
    <Modal isOpen={isOpen} onClose={onClose} showCloseButton={false}>
      <div className="flex flex-col w-[85vw] h-[90vh] pointer-events-auto overflow-auto">
        <div className="flex flex-row p-4 border-b justify-between items-center h-20">
          <div className="flex flex-col gap-1">
            <div className="flex justify-center items-center gap-2 text-md font-semibold">
              {fileName}
              {externalAssetId && (
                <div className="flex items-center text-sm text-blue-2 border-blue-3 bg-blue-4 rounded-xl p-2 mt-1 font-semibold w-fit max-w-[200px] overflow-hidden">
                  {`TAB ID: ${externalAssetId}`}
                </div>
              )}
            </div>
          </div>
          <div className="flex gap-4 items-center">
            <ZoomIn className="cursor-pointer" onPointerDown={handleZoomIn} />
            <ZoomOut className="cursor-pointer" onPointerDown={handleZoomOut} />
            <div className="text-gray-1">|</div>
            <Cross
              className="cursor-pointer"
              onPointerDown={(e: React.PointerEvent<Element>) => {
                e.stopPropagation();
                onClose();
              }}
            />
          </div>
        </div>
        <div className="flex flex-1">
          <div className="flex flex-col w-full h-[calc(90vh-100px)] items-center overflow-auto my-2">
            {imageUrl && (
              <img
                src={imageUrl}
                alt="Selected image preview"
                className="w-[55%] h-full border shadow-lg overflow-auto"
                style={{
                  transform: `scale(${zoomLevel})`,
                  transformOrigin: 'top',
                  transition: 'transform 0.3s ease',
                  objectFit: 'contain',
                }}
              />
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ImagePreviewModal;

import { FC } from 'react';

import { Button, Modal } from '@components/index';
import { ASSET_INFO } from '@pages/home-page/constants';
import { AssetType } from '@pages/home-page/enums';
import { useDeleteAsset } from '@pages/home-page/hooks/use-delete-asset';
import { AssetToDelete } from '@pages/home-page/types';

interface DeleteAssetModalProps {
  assetToDelete: AssetToDelete;
  onCloseModal: () => void;
}

const DeleteAssetModal: FC<DeleteAssetModalProps> = ({
  assetToDelete,
  onCloseModal,
}) => {
  const { mutateAsync: deleteBrandAsset, isLoading: isDeletingAsset } =
    useDeleteAsset();

  const assetType = ASSET_INFO[assetToDelete.assetType as AssetType].name
    .toLowerCase()
    .slice(0, -1);

  const isDocumentDelete =
    ASSET_INFO[assetToDelete.assetType as AssetType].name ===
    ASSET_INFO[AssetType.RAW_FILE].name;

  const onDeleteButtonClick = async () => {
    await deleteBrandAsset({
      assetId: assetToDelete.id as number,
      isDocumentDelete: isDocumentDelete,
    });
    onCloseModal();
  };

  return (
    <Modal
      isOpen={!!assetToDelete.id && !!assetToDelete.assetType}
      onClose={onCloseModal}
    >
      <div className="px-6 py-8 w-[620px] flex flex-col gap-2">
        <div className="text-lg font-medium">
          {isDocumentDelete
            ? 'Delete Document?'
            : `Would you like to delete this ${assetType}?`}
        </div>
        <span className="text-gray-8">
          {isDocumentDelete
            ? 'Are you sure you want to delete this document? This action cannot be undone. The document and all associated attributes will also be deleted.'
            : 'This action is irreversible.'}{' '}
        </span>
      </div>
      <div className="justify-center flex mb-8 space-x-4">
        <Button
          label="No, cancel"
          onClick={onCloseModal}
          classOverride="!w-[260px] !border-gray-4 !text-gray-9 !font-medium"
          isDisabled={isDeletingAsset}
        />
        <Button
          label="Yes, delete"
          classOverride="!w-[260px] !border-red-3 !bg-red-3 !text-white"
          onClick={onDeleteButtonClick}
          isDisabled={isDeletingAsset}
        />
      </div>
    </Modal>
  );
};

export default DeleteAssetModal;

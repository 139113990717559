import { useMutation } from 'react-query';

import { HttpMethod } from '@api/enums';
import { createHttpClient } from '@api/http-client';
import { END_POINTS } from '@constants/api-endpoints';
import { AlertType } from '@enums/alert';
import { showAlert } from '@lib/alert/alert-manager';
import { Language } from '@pages/home-page/enums';

export type ChatRequestResponse = {
  session_id: string;
  status: string;
};

export const useChatRequest = () => {
  const client = createHttpClient();

  const mutation = useMutation(
    ({
      humanMessage,
      language,
      session_id,
    }: {
      humanMessage: string;
      language: Language;
      session_id: string;
    }) =>
      client({
        method: HttpMethod.POST,
        url: END_POINTS.CHAT.CHAT_REQUEST,
        data: {
          human_message: humanMessage,
          language,
          session_id,
        },
      }),
    {
      onError: (error: Error) => {
        showAlert({
          type: AlertType.ERROR,
          header: 'Failed to send chat request',
          message: `${error?.message || 'Request failed'}`,
        });
      },
    },
  );

  return mutation;
};

import { useState } from 'react';

import { useRecoilValue } from 'recoil';

import { Document } from '@assets/icons';
import { Spinner } from '@components/index';
import { RetrievedDocument } from '@hooks/types';
import { ChatRole } from '@pages/home-page/enums';
import { chatList, ChatListItem, chatLoaderState } from '@store/atoms/chat';

import ChunkPreviewModal from './chunk-preview-modal/ChunkPreviewModal';

const ChatList = () => {
  const chatListItems = useRecoilValue(chatList);
  const isChatLoading = useRecoilValue(chatLoaderState);

  const [openPreviewModal, setOpenPreviewModal] = useState(false);
  const [documentList, setDocumentList] = useState<RetrievedDocument[] | null>(
    null,
  );

  const formatChatResponse = (text: string) => {
    return text.split('\n').map((line, index) => {
      // Handle bold text by finding **text** and wrapping it in <strong>
      const lineKey = `line-${index}-${line}`;
      const boldLine = line.split(/(\*\*.*?\*\*)/g).map((part, idx) => {
        const partIndex = `part-${idx}-${part}`;
        if (part.startsWith('**') && part.endsWith('**')) {
          return <strong key={partIndex}>{part.slice(2, -2)}</strong>;
        }

        return part;
      });

      // Return paragraphs with proper spacing
      return (
        <p key={lineKey} className="mb-4 last:mb-0">
          {boldLine}
        </p>
      );
    });
  };

  return (
    <div className="h-full overflow-auto">
      {chatListItems.map((item: ChatListItem) => {
        return (
          <div
            className={`flex flex-col gap-2 p-4 ${item.role === ChatRole.HUMAN ? 'items-end' : ''}`}
            key={item.data.response}
          >
            <div className="flex text-sm font-bold">
              {item.role === ChatRole.HUMAN ? 'You' : 'Brand DNAi'}
            </div>
            <div
              className={`text-sm text-dark-2 ${item.role === ChatRole.HUMAN ? 'bg-lighter p-2 rounded-tl-[20px] rounded-b-[20px] w-fit max-w-[350px]' : ''}`}
            >
              {formatChatResponse(item.data.response)}
            </div>
            {item.data.metadata &&
              item.data.metadata.retrieved_docs?.length > 0 && (
                <div className="bg-blue-1 p-3">
                  <div className="text-sm">
                    Note: The response is based on insights gathered from the
                    following documents.
                  </div>
                  <div
                    className="flex items-center text-[13px] text-blue-2 border rounded-xl p-2 w-fit mt-1 font-semibold cursor-pointer"
                    onClick={() => {
                      setOpenPreviewModal(true);
                      setDocumentList(
                        item?.data?.metadata?.retrieved_docs || null,
                      );
                    }}
                  >
                    <Document className="mr-2" />
                    View source document(s)
                  </div>
                </div>
              )}
          </div>
        );
      })}
      {isChatLoading && (
        <div className="flex flex-col gap-2 p-4">
          <div className="text-sm font-bold">Brand DNAi</div>
          <Spinner classOverride="!justify-start" />
        </div>
      )}
      {openPreviewModal && (
        <ChunkPreviewModal
          isOpen={openPreviewModal}
          onClose={() => {
            setOpenPreviewModal(false);
            setDocumentList(null);
          }}
          documentList={documentList as RetrievedDocument[]}
        />
      )}
    </div>
  );
};

export default ChatList;
